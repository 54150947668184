import React from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Skeleton,
  IconButton,
  Tooltip,
  Button,
  TextField,
  InputAdornment,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ViewModuleIcon from "@mui/icons-material/ViewKanban";
import ViewGridIcon from "@mui/icons-material/TableView";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import DownloadIcon from "@mui/icons-material/Download";
import MoreVert from "@mui/icons-material/MoreVert";
import Close from "@mui/icons-material/Close";
import Save from "@mui/icons-material/Save";
import { TaskList, Process } from "@/api/Process";
import { useQueryParam, BooleanParam, withDefault } from "use-query-params";
import { canEditProcessFields } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import Progress from "@/ui/atoms/Progress";
import {
  SettingsApplications, TextFields, ViewQuilt,
} from "@mui/icons-material";
import FieldEditor from "../FieldEditor";
import ViewEditor from "../ViewEditor";

type HeaderProps = {
  processId: string;
  processName: string;
  groupBy: string;
  setGroupBy: (value: string) => void;
  groupableFields: TaskList["fields"];
  view: string;
  showBoardView: () => void;
  showTableView: () => void;
  showArchived: () => void;
  canEdit?: boolean;
  canEditFields?: boolean;
  canEditViews?: boolean;
  addTask: () => void;
  exportProcess: (archived: boolean) => void;
  entityName: string;
  boardViewDisabled?: boolean;
  query: string;
  onQueryChange: (value: string) => void;
  onProcessNameChange: (value: string) => void;
  disableAddTask: boolean;
  forms: Process["forms"];
  onFormActionClick: (formId: string) => void;
  addButtonLabel: string | boolean;
};

export default function Header({
  processId,
  processName,
  groupBy,
  setGroupBy,
  groupableFields,
  view,
  showBoardView,
  showTableView,
  showArchived,
  canEdit,
  canEditFields,
  canEditViews,
  exportProcess,
  addTask,
  onFormActionClick,
  entityName,
  boardViewDisabled = false,
  onQueryChange,
  onProcessNameChange,
  query,
  disableAddTask,
  forms,
  addButtonLabel,
}: HeaderProps) {
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [isBusy, setBusy] = React.useState(false);
  const [showEditMenu, setShowEditMenu] = React.useState(false);
  const [nameEditable, setNameEditable] = React.useState(false);

  const [showFormMenu, setShowFormMenu] = React.useState(false);
  const formMenuAnchorRef = React.useRef<HTMLButtonElement>(null);

  const [fieldsEditable, setFieldsEditable] = useQueryParam(
    "fieldsEditable",
    withDefault(BooleanParam, false),
  );
  const [viewsEditable, setViewsEditable] = useQueryParam(
    "viewsEditable",
    withDefault(BooleanParam, false),
  );
  const { user } = useAuth();

  const [tempName, setTempName] = React.useState(processName);
  React.useEffect(() => {
    setTempName(processName);
  }, [processName]);

  React.useEffect(() => {
    if (!canEditProcessFields(user) && fieldsEditable) {
      setFieldsEditable(false);
    }
  }, [fieldsEditable, setFieldsEditable, user]);

  React.useEffect(() => {
    if (!canEditProcessFields(user) && viewsEditable) {
      setViewsEditable(false);
    }
  }, [viewsEditable, setViewsEditable, user]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      marginBottom={2}
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
        gap={{
          xs: 1,
          sm: 10,
        }}
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        width={{
          xs: "100%",
          sm: "auto",
        }}
      >
        {processName ? (
          <>
            {nameEditable ? (
              <TextField
                label="Name"
                value={tempName}
                onChange={(e) => setTempName(e.target.value)}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton
                        onClick={() => {
                          onProcessNameChange(tempName);
                          setNameEditable(false);
                        }}
                      >
                        <Save />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setTempName(processName);
                          setNameEditable(false);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </>
                  ),
                }}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              />
            ) : (
              <Typography
                variant="h2"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width={{
                  xs: "100%",
                  sm: "auto",
                }}
              >
                {processName}
                {(canEdit || canEditFields) && (
                  <>
                    <IconButton
                      onClick={() => setShowEditMenu(true)}
                      ref={menuRef}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={menuRef.current}
                      open={showEditMenu}
                      onClose={() => setShowEditMenu(false)}
                    >
                      {canEdit && (
                        <MenuItem
                          onClick={() => {
                            setNameEditable(true);
                            setShowEditMenu(false);
                          }}
                        >
                          <ListItemIcon>
                            <TextFields />
                          </ListItemIcon>
                          <ListItemText>Edit Name</ListItemText>
                        </MenuItem>
                      )}
                      {canEditFields && (
                        <MenuItem
                          onClick={() => {
                            setFieldsEditable(true);
                            setShowEditMenu(false);
                          }}
                        >
                          <ListItemIcon>
                            <SettingsApplications />
                          </ListItemIcon>
                          <ListItemText>Edit Fields</ListItemText>
                        </MenuItem>
                      )}
                      {canEditViews && (
                        <MenuItem
                          onClick={() => {
                            setViewsEditable(true);
                            setShowEditMenu(false);
                          }}
                        >
                          <ListItemIcon>
                            <ViewQuilt />
                          </ListItemIcon>
                          <ListItemText>Edit Views</ListItemText>
                        </MenuItem>
                      )}
                    </Menu>
                  </>
                )}
              </Typography>
            )}

            <Box
              display="flex"
              alignItems="center"
              gap={1}
              width={{
                xs: "100%",
                sm: "auto",
              }}
            >
              <TextField
                label={`Search ${entityName} `}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={query}
                onChange={(e) => onQueryChange(e.target.value)}
                type="search"
                sx={{
                  minWidth: 120,
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
                data-cy="process-global-search"
              />

              <Tooltip title="Only Searches Project Name, Description and Primary Company">
                <InfoIcon
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                />
              </Tooltip>
            </Box>
          </>
        ) : (
          <>
            <Skeleton variant="text" width={120} height={56} />
            <Skeleton variant="rounded" width={240} height={56} />
          </>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        width={{
          xs: "100%",
          sm: "auto",
        }}
      >
        {forms?.length === 1 && (
          forms.map(({ id, title }) => (
            <Button
              key={id}
              color="primary"
              variant="outlined"
              startIcon={<PostAddIcon />}
              onClick={() => onFormActionClick(id)}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              {title}
            </Button>
          ))
        )}
        {forms?.length > 1 && (
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
          >
            <Button
              ref={formMenuAnchorRef}
              color="primary"
              variant="outlined"
              startIcon={<PostAddIcon />}
              onClick={() => setShowFormMenu(true)}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              Open Form
            </Button>
            <Menu
              open={showFormMenu}
              onClose={() => setShowFormMenu(false)}
              anchorEl={formMenuAnchorRef.current}
            >
              {forms.map(({ id, title }) => (
                <MenuItem
                  key={id}
                  onClick={() => {
                    onFormActionClick(id);
                    setShowFormMenu(false);
                  }}
                >
                  <ListItemIcon>
                    <PostAddIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {title}
                  </ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}

        {processName ? (
          <Tooltip title={`Add ${entityName}`} arrow>
            <Button
              color="primary"
              onClick={() => addTask()}
              startIcon={<PostAddIcon />}
              variant="contained"
              data-cy="add-task-button"
              disabled={disableAddTask}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              {addButtonLabel || `Add ${entityName}`}
            </Button>
          </Tooltip>
        ) : (
          <Skeleton
            variant="rounded"
            width="100%"
            height={56}
            sx={{
              minWidth: "120px",
            }}
          />
        )}

        <Divider flexItem orientation="vertical" />

        {view !== "archived"
              && view !== "table"
              && (groupableFields.length ? (
                <>
                  <FormControl style={{ minWidth: "200px" }} margin="dense">
                    <InputLabel id="group-by" shrink>
                      Group Tasks By
                    </InputLabel>
                    <Select
                      value={groupBy}
                      onChange={(e) => setGroupBy(e.target.value as string)}
                      labelId="group-by"
                      label="Group Tasks By"
                      placeholder="Select Department"
                      size="small"
                    >
                      {groupableFields.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Divider flexItem orientation="vertical" />
                </>
              ) : (
                <>
                  <Skeleton variant="rounded" width={200} height={56} />
                  <Divider flexItem orientation="vertical" />
                </>
              ))}

        {/* <Tooltip title="Switch to List View" arrow>
              <IconButton disabled={view === "list"} onClick={() => showListView()}>
                <ViewListIcon />
              </IconButton>
            </Tooltip> */}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
        >
          <Tooltip title="Switch to Table View" arrow>
            <IconButton
              disabled={view === "table"}
              onClick={() => showTableView()}
            >
              <ViewGridIcon />
            </IconButton>
          </Tooltip>
          {!boardViewDisabled && (
          <Tooltip title="Switch to Board View" arrow>
            <IconButton
              disabled={view === "board"}
              onClick={() => showBoardView()}
            >
              <ViewModuleIcon />
            </IconButton>
          </Tooltip>
          )}

          <Divider flexItem orientation="vertical" />
          {/* fix with adding pluralEntityName in future */}
          {/* <Tooltip title={`View Archived ${entityName}s`} arrow> */}
          <Tooltip title="View Archived Tasks" arrow>
            <IconButton
              disabled={view === "archived"}
              onClick={() => showArchived()}
              data-cy="view-archived-button"
            >
              <RestoreFromTrashIcon />
            </IconButton>
          </Tooltip>

          <Divider flexItem orientation="vertical" />

          <Tooltip title="Export Data" arrow>
            <IconButton
              disabled={isBusy}
              onClick={async () => {
                setBusy(true);
                try {
                  await exportProcess(view === "archived");
                } finally {
                  console.log("finally");
                  setBusy(false);
                }
              }}
            >
              {isBusy ? <Progress /> : <DownloadIcon />}
            </IconButton>
          </Tooltip>
        </Box>

      </Box>

      {fieldsEditable && (
        <FieldEditor
          processId={processId}
          open={fieldsEditable}
          onClose={() => setFieldsEditable(false)}
        />
      )}

      {viewsEditable && (
        <ViewEditor
          processId={processId}
          open={viewsEditable}
          onClose={() => setViewsEditable(false)}
        />
      )}
    </Box>
  );
}
