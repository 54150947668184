import React from "react";
import { useQuery } from "react-query";
import {
  getProcess, getTask, getForm, updateTaskFieldValue,
} from "@/api/Process";
import { updateOrgFieldValue } from "@/api/Organization";
import Form from "./Form";

export default function FormContainer({
  processId,
  formId,
  oppId,
  onCancel,
  onSubmit,
}: {
  processId: string;
  formId: string;
  oppId: string;
  onCancel: () => void;
  onSubmit: (values: any) => void;
}) {
  // busy
  const [busy, setBusy] = React.useState(false);

  // get form
  const {
    data: formData,
    isLoading: formLoading,
  } = useQuery(["form", formId], () => getForm(processId, formId), { enabled: !!formId });

  // get opportunity
  const {
    data: oppData,
    isLoading: oppLoading,
  } = useQuery(["opportunity", oppId], () => getTask(processId, oppId), { enabled: !!oppId });

  // get process for primary company id
  const {
    data: processData,
    isLoading: processLoading,
  } = useQuery(["process", processId], () => getProcess(processId), { enabled: !!processId });

  const isLoading = formLoading || oppLoading || processLoading;

  return (
    <Form
      loading={isLoading}
      disabled={busy}
      structure={formData}
      value={oppData}
      onCancel={() => onCancel()}
      onSubmit={async (values) => {
        setBusy(true);

        const valueEntries = Object.entries(values).map(([key, value]) => ({
          fieldId: key,
          value,
        }));

        try {
          await Promise.all(
            valueEntries.map((entry) => {
              // get field
              const field = formData.sections
                .map((section) => section.fields)
                .flat()
                .find((f) => f.id === entry.fieldId);

              let { value } = entry;
              if (field.type === "select_multi") {
                value = value.choices.map((v) => ({ choiceId: v }));
              }
              if (field.type === "user_multi") {
                value = value.users.map((v) => ({ userId: v.id }));
              }
              if (field.type === "person_multi") {
                value = value.persons.map((v) => ({ personId: v.id }));
              }
              if (field.type === "checkbox") {
                value = value ? [{ value: "Yes" }] : [{ value: "No" }];
              }
              value = Array.isArray(value) ? value : [value];

              if (field.isOrganizationField) {
                // get company
                const companyField = processData.fields.find((f) => f.isPrimary && f.type === "company");
                const companyValue = oppData.fieldValues[companyField.id]?.company;
                return updateOrgFieldValue(companyValue.valorId, field.settingId, value);
              }

              return updateTaskFieldValue(
                processId,
                oppId,
                entry.fieldId,
                value,
              );
            }),
          );
        } catch (e) {
          console.error(e);
        }
        onSubmit(values);
        setBusy(false);
      }}
    />
  );
}
