import React from "react";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";

export default function DateCell(
  { value, format = "MM/DD/YYYY" }: { value: number | string | null, format?: string },
) {
  let content = null;
  if (value === null || value === undefined) {
    content = "-";
  } else {
    content = dayjs(value).utc().format(format || "MM/DD/YYYY");
  }
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
      <Typography variant="body2">
        {content}
      </Typography>
    </Box>
  );
}
